import { DictionaryWord } from '../../@shared/models/responses/dictionary-word.model';

export interface UserDataResponse {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  avatarUrl: string;
  accountType: DictionaryWord;
  isInvoiceModuleEnabled?: boolean;
  isInvoiceVerification?: boolean;
  isCalendarModuleEnabled?: boolean;
  isInvestorModuleEnabled?: boolean;
  permissions: UserPerm[];
}

export interface UserPerm {
  action: DictionaryWord;
  role: DictionaryWord;
  tag: DictionaryWord;

  create: boolean;
  delete: boolean;
  read: boolean;
  update: boolean;
}

export interface LoginRequest {
  email: string;
  password: string;
  rememberMe: boolean;
  newPassword: string | null;
  newPasswordConfirmation: string | null;
}

export interface LoginResponse {
  token: string;
}

export interface RemindPasswordRequest {
  email: string;
}

export interface ResetPasswordRequest {
  token: string;
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordResponse {}
export interface RemindPasswordResponse {}

export const AUTH_CONSTANTS = {
  ACCOUNT_TYPE: {
    SYSTEM_USER: 'SystemUser',
    SYSTEM_ADMIN: 'SystemAdministrator',
  },
};
